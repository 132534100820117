import React from 'react';
import PropTypes from 'prop-types';
import { Menu, animation } from 'react-contexify';

const ContextMenu = ({ children, id }) => {
    return (
        <Menu id={id} animation={animation.pop}>
        	{children}
        </Menu>
    );
};

ContextMenu.displayName = 'ContextMenu';

ContextMenu.propTypes = {
    id: PropTypes.string,
};

export { ContextMenu };
