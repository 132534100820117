import React from 'react';
import PropTypes from 'prop-types';
import { Dashboard } from '../components';
import HelmetComponent from '../helpers/HelmetComponent';

const DashboardPage = ({ className }) => {
    return (
    	<div>
    		<HelmetComponent title='Dashboard' />
        	<Dashboard />
        </div>
    );
};

DashboardPage.displayName = 'DashboardPage';

DashboardPage.propTypes = {
    className: PropTypes.string,
};

export { DashboardPage };
