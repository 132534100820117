import React from 'react';
import PropTypes from 'prop-types';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from 'draft-js';

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

const Link = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a rel="nofollow noreferrer" href={url} target="_blank">
      {props.children}
    </a>
  );
};

const decorator = new CompositeDecorator([{
  strategy: findLinkEntities,
  component: Link
}]);

const convertToEditorState = (editorContent) => {
  const content = convertFromRaw(JSON.parse(editorContent));
  const editorState = EditorState.createWithContent(content, decorator);
  return editorState;
};

const DisplayEditor = ({ content }) => {
    return (
        <Editor editorState={convertToEditorState(content)} readOnly />
    );
};

DisplayEditor.displayName = 'DisplayEditor';

DisplayEditor.propTypes = {
    className: PropTypes.string,
};

export { DisplayEditor };
