import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Home extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div />
        );
    }
}

export { Home };
