import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from 'react-apollo';
import App from './App';

const Root = ({ history, store, client, supportsHistory }) => {
    return (
    	<ApolloProvider client={client}>
			<CookiesProvider>
		    	<BrowserRouter forceRefresh={!supportsHistory}>
			        <Provider store={store}>
			        	<Route component={App} history={history} />
			        </Provider>
		        </BrowserRouter>
		    </CookiesProvider>
		</ApolloProvider>
    );
};

Root.displayName = 'Root';

Root.propTypes = {
    store: PropTypes.object,
};

export { Root };
