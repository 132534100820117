import { 
	HomePage, 
	DashboardPage,
	BannersPage,
	HoroskopsPage,
	MandorsPage,
	UploadsPage,
	UsersPage
} from '../pages';

const guests = [
	{
		path: '/',
		label: 'Home',
		exact: true,
		component: HomePage
	}
];

const admins = [
	{
		path: '/account/dashboard',
		label: 'Dashboard',
		exact: true,
		component: DashboardPage
	},
	{
		path: '/account/banners',
		label: 'Banners',
		exact: true,
		component: BannersPage
	},
	{
		path: '/account/horoskops',
		label: 'Horoskops',
		exact: true,
		component: HoroskopsPage
	},
	{
		path: '/account/mandors',
		label: 'Mandors',
		exact: true, 
		component: MandorsPage
	},
	{
		path: '/account/uploads',
		label: 'Uploads',
		exact: true, 
		component: UploadsPage
	},
	{
		path: '/account/users',
		label: 'Users',
		exact: true,
		component: UsersPage
	},
];

export { guests, admins };