import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class TextEditor extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    // constructor(props) {
    //     super(props);
    // }

    render() {
    	const { editorState, onEditorStateChange } = this.props;
        return (
            <Editor
	          editorState={editorState}
	          wrapperClassName='demo-wrapper sae-editor'
	          editorClassName='demo-editor'
	          onEditorStateChange={onEditorStateChange}
	        />
        );
    }
}

export { TextEditor };
