import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Navbar } from '../containers';
import { admins, guests } from '../routes/web';
import '../App.css';

function App({ location }) {
  return (
  	<Switch>
  	{guests.map((route, i) => (
        <Route key={route.label} location={location} path={route.path} exact={route.exact} component={route.component} />
    ))}
    <Navbar location={location}>
      <Switch>
        {admins.map((route, i) => (
            <Route key={route.label} location={location} path={route.path} exact={route.exact} component={route.component} />
        ))}
      </Switch>
    </Navbar>
    </Switch>
  );
}

export default App;
