import { createStore, applyMiddleware } from 'redux';
// import Cookies from 'universal-cookie';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
// import { userLoggedIn } from '../actions';
import rootReducer from '../reducers';

// const cookies = new Cookies();

export default function configureStore() {
  const store = createStore(
  	rootReducer,
  	composeWithDevTools(
  		applyMiddleware(thunk)
  	)
  );

  // if(cookies.get('sae-app')){
  //   const data = cookies.get('sae_news');
  //   store.dispatch(userLoggedIn(data));
  // };

  return store;
}
