import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

const DropzoneImg = ({ files, onPreviewDrop }) => {
	const thumbs = files.map((file, i) => (
      <img
      	key={i}
        src={file.preview ? file.preview : file.cover}
        style={{width: '100%'}}
        alt='preview'
      />
    ));
    return (
        <Dropzone 
			accept='image/*' 
			onDrop={onPreviewDrop}>
			{({getRootProps, getInputProps, isDragActive}) => {
			return (
				<div
				{...getRootProps()}
				id='dropzone'
				>
					<div> 
					  {files.length > 0 ? thumbs : 
						  <div id='ad-empty'/>
					  }
					</div>
					<input {...getInputProps()} />
				</div>
			)
			}}
		</Dropzone>
    );
};

DropzoneImg.displayName = 'DropzoneImg';

DropzoneImg.propTypes = {
    onPreviewDrop: PropTypes.func.isRequired,
};

export { DropzoneImg };