import React from 'react';
import { Menu } from 'semantic-ui-react';
import { MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

const Main = ({ children, id, label, navigation, contextMenu, ref }) => {
    return (
        <MenuProvider id={contextMenu}>
            <section id={id} style={{marginTop: '7em', minHeight: '100vh', padding: '10px'}} ref={ref}>
                <Menu size='small' secondary borderless className='main-menu'>
                    <Menu.Item>{label}</Menu.Item>
                    {navigation}
                </Menu>
            	<div style={{ marginTop: '2em' }}>
                    {children}
            	</div>
            </section>
        </MenuProvider>
    );
};

Main.displayName = 'Main';

export { Main };
