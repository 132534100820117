import Cookies from 'universal-cookie';
import { ApolloClient } from 'apollo-client';
import { from, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
// import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';

const cookies = new Cookies();

const uploadLink = new createUploadLink({ 
  uri: 'https://manages.sae-desa.com/v01/graphql/admin/sae',
});

// const authLink = setContext((_, { headers }) => {
//   const accessToken = cookies.get('sae_news');
//   return {
//     headers: {
//       ...headers,
//       authorization: accessToken ? `Bearer ${accessToken}` : `Bearer ${guestToken}`
//     }
//   }
// });

const wsLink = new WebSocketLink({
  uri: 'ws://manages.sae-desa.com/graphql',
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      authToken: `Bearer ${cookies.get('sae-merchant')}`,
    }),
    connectionCallback: err => {
      if (err) {
       console.log(err);
      }
    },
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
    {
      if(message.includes('expired')){
        window.location.reload();
      }
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    }
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const clientlink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  uploadLink
);

const link = from([
  errorLink,
  clientlink
]);

const configGraphql = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

export default configGraphql;