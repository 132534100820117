import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const HelmetComponent = ({ className, title, description, contextMenu, children }) => {
	const defaulTitle = 'Sae Manages';
    return (
        <Helmet>
        	<title>{title ? `${title} - ${defaulTitle}`: defaulTitle}</title>
        	<meta name='description' content={description ? description : defaulTitle} />
        </Helmet>
    );
};

HelmetComponent.displayName = 'HelmetComponent';

HelmetComponent.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
};

export default HelmetComponent;
