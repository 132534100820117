import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { Button, Modal, TransitionablePortal } from 'semantic-ui-react';
import { LOAD_ALL_BANNER, REMOVE_BANNER } from '../../graphql';

const RemoveModal = ({ handleModal, rowId, open }) => {
	const [removeBanner] = useMutation(REMOVE_BANNER, {
		update(cache, { data: { removeBanner }}) {
			const { loadAllBanner } = cache.readQuery({ query: LOAD_ALL_BANNER });
			const banners = loadAllBanner.filter(banner => banner.id !== removeBanner.id);
			cache.writeQuery({
				query: LOAD_ALL_BANNER,
				data: { loadAllBanner: banners }
			})
		}
	});
    return (
    	<TransitionablePortal
	      open={open}
	      transition={{ animation: 'fade right', duration: 500 }}
	    >
    	<Modal size='small' open={true} centered={false}>
          <Modal.Header>Delete Banner</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this banner ?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleModal}>No</Button>
            <Button
              color='red'
              icon='trash'
              labelPosition='right'
              content='Yes'
              onClick={e => {
              	removeBanner({ variables: { id: rowId } });
              	handleModal();
              }}
            />
          </Modal.Actions>
        </Modal>
        </TransitionablePortal>
    );
};

RemoveModal.displayName = 'RemoveModal';

RemoveModal.propTypes = {
    className: PropTypes.string,
};

export default RemoveModal;
