import React from 'react';
import PropTypes from 'prop-types';
import { Horoskops } from '../components';
import HelmetComponent from '../helpers/HelmetComponent';

const HoroskopsPage = ({ className }) => {
    return (
    	<div>
    		<HelmetComponent title='Horoskops' />
        	<Horoskops />
        </div>
    );
};

HoroskopsPage.displayName = 'HoroskopsPage';

HoroskopsPage.propTypes = {
    className: PropTypes.string,
};

export { HoroskopsPage };
