import gql from 'graphql-tag';


// Banners

export const LOAD_ALL_BANNER = gql`
query LOAD_ALL_BANNER {
	loadAllBanner {
		id
		image
		title
		url
		category
	}
}
`;

export const LOAD_ONE_BANNER = gql`
query LOAD_ONE_BANNER($id: ID!) {
	loadOneBanner(id: $id) {
		id
		image
		title
		url
		category
	}
}
`;


// Horoskops

export const LOAD_ALL_HOROSKOP = gql`
query LOAD_ALL_HOROSKOP {
	loadAllHoroskop {
		id
		title
		slug
		image
		content
		descriptions
	}
}
`;

export const LOAD_ONE_HOROSKOP = gql`
query LOAD_ONE_HOROSKOP($id: ID!) {
	loadOneHoroskop(id: $id) {
		id
		title
		slug
		image
		content
		descriptions
	}
}
`;


// Mandors

export const LOAD_ALL_MANDOR = gql`
query LOAD_ALL_MANDOR {
	loadAllMandor {
		id
		user {
			id
			name
			mandor
		}
		pbb {
			nop
			name
			address
			ammount
		}
	}
}
`;

export const LOAD_ONE_MANDOR = gql`
query LOAD_ONE_MANDOR($id: ID!) {
	loadOneMandor(id: $id) {
		id
		user {
			id
			name
			mandor
		}
		pbb {
			nop
			name
			address
			ammount
		}
	}
}
`;

// Pbb

export const LOAD_ALL_PBB = gql`
query LOAD_ALL_PBB {
	loadAllPbb {
		nop
		nama
		alamat
		ketetapan
	}
}
`;


// Users

export const LOAD_ALL_USER = gql`
query LOAD_ALL_USER {
	loadAllUser {
		id
		name
		phone
		mandor
		merchant
		createdAt
	}
}
`;

export const LOAD_ALL_USER_MANDOR = gql`
query LOAD_ALL_USER_MANDOR {
	loadAllUserMandor {
		id
		name
		phone
		mandor
		merchant
		createdAt
	}
}
`;

export const LOAD_ONE_USER = gql`
query LOAD_ONE_USER($id: ID!) {
	loadOneUser(id: $id) {
		id
		name
		phone
		mandor
		merchant
	}
}
`;