import React from 'react';
import PropTypes from 'prop-types';
import { Mandors } from '../components';

const MandorsPage = ({ className }) => {
    return (
        <Mandors />
    );
};

MandorsPage.displayName = 'MandorsPage';

MandorsPage.propTypes = {
    className: PropTypes.string,
};

export { MandorsPage };
