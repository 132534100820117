import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Dropdown, Loader } from 'semantic-ui-react';
import { LOAD_ALL_USER_MANDOR } from '../graphql';

const nullOptions = [{ key: 1, text: 'Empty', value: 1 }];
const GetMandors = ({ mandor, onSelect, type }) => (
<Query query={LOAD_ALL_USER_MANDOR}>
    {({loading, data, error}) => {
        if(loading) return <Loader active inline='centered' />;
        if(error) return <Dropdown selection options={nullOptions} placeholder={error.message} error />;
        const mandors = !loading && !!data.loadAllUserMandor.length ? data.loadAllUserMandor.map((mandor, i) => (
            { key: i, text: `${mandor.name} - ${mandor.phone}`, value: mandor.id }
        )) : [{}];
        return (
            <Dropdown
                placeholder='Select Mandor'
                fluid
                search
                clearable
                selection
                loading={loading}
                value={mandor}
                options={mandors}
                onChange={onSelect}
              />
        );
    }}
</Query>
);

class DropdownMandor extends Component {
    static propTypes = {
        className: PropTypes.string,
        location: PropTypes.shape({
          pathname: PropTypes.string.isRequired
        })
    };

    render() {
        const { data, onSelect } = this.props;
        return (
            <GetMandors
              mandor={data}
              onSelect={onSelect} 
             />
        );
    }
}

export { DropdownMandor };
