import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Menu, Item, MenuProvider, theme, animation } from 'react-contexify';
import { List, Loader, Icon, Grid } from 'semantic-ui-react';
import { LOAD_ALL_MANDOR } from '../graphql';
import { Empty, Main } from '../containers';
import AddModal from './ad-mandors/AddModal';
import EditModal from './ad-mandors/EditModal';

class Mandors extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    constructor(props) {
    	super(props);
    	this.state = {
    		openAdd: false,
            openEdit: false,
            listId: '',
            rowId: ''
    	}
    }

    handleAddModal = () => this.setState((prevState) => ({
    	openAdd: !prevState.openAdd
    }));

    handleEditModal = (id) => this.setState((prevState) => ({
        openEdit: !prevState.openEdit,
        rowId: id
    }));

    onSelect = (e, id) => {
      this.setState({ listId: id });
    };

    unSelect = e => {
      this.setState({ listId: '' });
    };

    render() {
        const { openAdd, openEdit, listId, rowId } = this.state;
        return (
            <Main label='Mandors' contextMenu='mandors_page'>
                <AddModal open={openAdd} handleModal={this.handleAddModal} />
                {!!openEdit && <EditModal listId={rowId} open={openEdit} handleModal={this.handleEditModal} />}
            	<Grid>
            		<Grid.Row>
            			<Grid.Column computer={16}>
            				<Query query={LOAD_ALL_MANDOR}>
                              {({ data, error, loading }) => {
                                if(loading) return <Loader active inline='centered' />;
                                if(error) return <p>{error}</p>;
                                if(!loading && !data.loadAllMandor.length) return <Empty message='No Mandor Yet..' />;
                                return (
                                <MenuProvider id='mandors_menu'>
                                    <List divided selection relaxed size='large'>
                                        {data.loadAllMandor.map((mandor, i) => (
                                            <List.Item active={mandor.id === listId} style={{paddingTop: '20px', paddingBottom: '20px'}} onClick={(e) => this.onSelect(e, mandor.id)} onContextMenu={(e) => this.onSelect(e, mandor.id)} key={i}>
                                                <List.Icon name='user outline' size='large' />
                                                <List.Content>
                                                   <List.Header>{mandor.user.name}</List.Header>
                                                   <List.Description>
                                                     <Icon name='database' /> {mandor.user.mandor ? 'Mandor': 'User'} 
                                                     <span style={{marginLeft: '5px'}}>
                                                         <Icon name='list' />
                                                         <b style={{marginLeft: '1em'}}>{mandor.pbb.length}</b>
                                                     </span>
                                                   </List.Description>
                                                </List.Content>
                                            </List.Item>
                                        ))}
                                    </List>
                                    <Menu id='mandors_menu' animation={animation.pop}>
                                       <Item onClick={() => this.handleEditModal(listId)}>
                                         <Icon name='edit' color='orange'/>
                                         Edit Mandor
                                       </Item>
                                       <Item>
                                         <Icon name='trash' color='red' />
                                         Delete Mandor
                                       </Item>
                                   </Menu>
                                </MenuProvider>
                                )}}
                            </Query>
            			</Grid.Column>
            		</Grid.Row>
            	</Grid>
            	<Menu id='mandors_page' animation={animation.pop}>
			       <Item onClick={this.handleAddModal}>
			       	 <Icon name='plus'/>
			       	 Attemp Mandor
			       </Item>
			   </Menu>
            </Main>
        );
    }
}

export { Mandors };
