import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Main } from '../containers';

class Dashboard extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        return (
            <Main label='Dashboard' />
        );
    }
}

export { Dashboard };
