import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { Item, MenuProvider } from 'react-contexify';
import { Button, List, Loader, Icon, Grid, Menu, Transition } from 'semantic-ui-react';
import { LOAD_ALL_USER, LOAD_ONE_USER, UPDATE_USER_ROLE } from '../graphql';
import { ContextMenu, Empty, Main } from '../containers';

class Users extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    constructor(props) {
    	super(props);
    	this.setRef = React.createRef();
    	this.state = {
    		openAdd: false,
    		listId: ''
    	}
    }

    onSelect = (e, id) => {
      this.setState({ listId: id });
    };

    unSelect = e => {
      this.setState({ listId: '' });
	};

    render() {
    	const { listId } = this.state;
        return (
            <Main label='Users' contextMenu='users_page' setRef={this.setRef} navigation={
            	<Menu.Menu position='right'>
        			<Transition
        			  visible={!!listId}
				      animation='slide left'
				      duration={500}
				    >
				   	   <Menu.Item>
        				 <Button circular basic icon='edit' onClick={() => this.handleEditModal(listId)} />
        				 <Button circular basic icon='trash' onClick={() => this.handleRemoveModal(listId)} />
        			   </Menu.Item>
        			</Transition>
        			<Menu.Item>
        				<Button circular basic color='green' icon='plus' onClick={this.handleAddModal} />
        			</Menu.Item>
            	</Menu.Menu>
            }>
            	<Grid>
            		<Grid.Row>
            			<Grid.Column computer={16}>
            				<Query query={LOAD_ALL_USER}>
                              {({ data, error, loading }) => {
                                if(loading) return <Loader active inline='centered' />;
                                if(error) return <p>{error}</p>;
                                if(!loading && !data.loadAllUser.length) return <Empty message='No User Yet..' />;
                                return (
                                	<MenuProvider id='users_menu'>
	                                    <List divided selection size='small'>
	                                        {data.loadAllUser.map((user, i) => (
	                                            <List.Item active={user.id === listId} style={{paddingTop: '20px', paddingBottom: '20px'}} key={i} onClick={(e) => this.onSelect(e, user.id)} onContextMenu={(e) => this.onSelect(e, user.id)}>
	                                                <List.Icon name='user outline' size='large' />
	                                                <List.Content>
	                                                   <List.Header>{`${user.name} - ${user.phone}`}</List.Header>
	                                                   <List.Description style={{marginTop: '10px'}}>
	                                                     <Icon name='database' /> {user.mandor ? 'Mandor': 'User'} 
	                                                     <span style={{marginLeft: '1em'}}>
	                                                         <Icon name='calendar alternate outline' />
	                                                         {user.createdAt}
	                                                     </span>
	                                                   </List.Description>
	                                                </List.Content>
	                                            </List.Item>
	                                        ))}
	                                    </List>
	                                    <ContextMenu id='users_menu'>
									       <Item>
									       	 <Icon name='edit' />
									       	 Edit User
									       </Item>
									       <Item>
									       	 <Icon name='trash' />
									       	 Delete User
									       </Item>
									       <Mutation 
						                      mutation={UPDATE_USER_ROLE} 
						                      update={(store, { data: { updateUserRole }}) => {
						                        const { loadOneUser } = store.readQuery({ query: LOAD_ONE_USER, variables: { id: listId } });
						                        loadOneUser.push(updateUserRole);
						                        store.writeQuery({
						                          query: LOAD_ALL_USER,
						                          data: { loadOneUser }
						                        })
						                      }}
						                    >
						                    {(updateUserRole, { data }) => (
										       <Item 
									       		onClick={(e) => {
						                          updateUserRole({
						                            variables: {
						                              id: listId,
						                              input: { 
						                              	mandor: true
						                              }
						                            }
						                          });
						                        }}>
										       	<Icon name='user secret' />
										       	Set as Mandor
										       </Item>
										     )}
						                   </Mutation>
									       <Item>
									       	<Icon name='user circle outline' />
									       	Set as Merchat
									       </Item>
									   </ContextMenu>
                                    </MenuProvider>
                                )}}
                            </Query>
            			</Grid.Column>
            		</Grid.Row>
            	</Grid>
			   <ContextMenu id='users_page'>
			       <Item>
			       	 <Icon name='plus' color='orange'/>
			       	 <span>Create User</span>
			       </Item>
			   </ContextMenu>
            </Main>
        );
    }
}

export { Users };
