import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Button, Form, Modal, TransitionablePortal } from 'semantic-ui-react';
import { UPDATE_HOROSKOP, LOAD_ONE_HOROSKOP } from '../../graphql';
import { DropzoneImg, TextEditor } from '../../containers';

class EditModal extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            title: '',
        	cover: '',
            content: '',
            descriptions: '',
            files: [],
        	loading: false
        }
    }

    componentDidMount() {
        this.fetchData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.fetchData(nextProps);
    }

    fetchData = (props) => {
        const { loadOneHoroskop, loading } = props.data;
        if(loading) return 'loading';
        const contentState = convertFromRaw(JSON.parse(loadOneHoroskop.content));
        this.setState({
            editorState: EditorState.push(this.state.editorState, contentState),
            title: loadOneHoroskop.title,
            cover: loadOneHoroskop.image,
            descriptions: loadOneHoroskop.descriptions,
            files: [{
                cover: loadOneHoroskop.image
            }]
        });
    };

    onEditorStateChange = (editorState) => this.setState({
      editorState
    });

    onChange = (e) => this.setState({ ...this.state, [e.target.name]: e.target.value });
    
    onChangeCategory = (e, { value }) => this.setState({
        category: value
    });

    onPreviewDrop = (files) => {
        this.setState({
            cover: files[0],
            files: files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        });
    };

    onSubmit = (e) => {
    	e.preventDefault();
    	this.setState({ loading: true });
    	this.props.mutate({
    		variables: {
                id: this.props.rowId,
    			input: {
                    title: this.state.title,
    				image: this.state.cover,
    				content: JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())),
                    descriptions: this.state.descriptions
    			}
    		},
    		update: (store, { data: { updateHoroskop } }) => {
    			const data = store.readQuery({ query: LOAD_ONE_HOROSKOP, variables: { id: this.props.rowId } });
    			data.loadOneHoroskop.push(updateHoroskop);
    			store.writeQuery({
    				query: LOAD_ONE_HOROSKOP,
    				data
    			});
    		}
    	}).then(() => {
            this.setState({ loading: false }); 
            this.props.handleModal(this.props.rowId)
        }).catch(err => console.log(err));
    }

    render() {
    	const { editorState, loading, files, title, descriptions } = this.state;
    	const { open, handleModal } = this.props;
        return (
            <TransitionablePortal
		      open={open}
		      transition={{ animation: 'fade up', duration: 500 }}
		    >
		    <Modal 
		        size='small'
		        open={true}
                centered={false}
		    >
		    	<Modal.Header>
		    	  Edit Horoskop
		        </Modal.Header>
		    	<Modal.Content>
		    		<Modal.Description style={{paddingLeft:'2em',paddingRight:'2em'}}>
		    			<Form loading={loading}>
		    				<Form.Field>
                                <label>Image</label>
                                <DropzoneImg files={files} onPreviewDrop={this.onPreviewDrop} />                  
                            </Form.Field>
                            <Form.Field>
                                <label>Title</label>
                                <Form.Input
                                    type='text'
                                    name='title'
                                    placeholder='Banner Title'
                                    value={title}
                                    onChange={this.onChange} 
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Descriptions</label>
                                <Form.TextArea
                                    name='descriptions'
                                    placeholder='Descriptions'
                                    value={descriptions}
                                    onChange={this.onChange} 
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Content</label>
                                <TextEditor 
                                    editorState={editorState} 
                                    onEditorStateChange={this.onEditorStateChange} 
                                />
                            </Form.Field>
		    			</Form>
		    		</Modal.Description>
		    	</Modal.Content>
		    	<Modal.Actions>
		    	  <Button basic onClick={() => handleModal(this.props.rowId)}>cancel</Button>
		          <Button color='blue' onClick={this.onSubmit}>Save</Button>        
		        </Modal.Actions>
		    </Modal>
		    </TransitionablePortal>
        );
    }
}

export default graphql(UPDATE_HOROSKOP)(
    graphql(LOAD_ONE_HOROSKOP, {
        options: (props) => ({
            variables: { id: props.rowId }
        })
    })(EditModal)
);