import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, Query } from 'react-apollo';
import { Button, Checkbox, Form, Modal, TransitionablePortal, Loader, List, Table, Segment } from 'semantic-ui-react';
import { ATTEMP_UPDATE_MANDOR, LOAD_ONE_MANDOR, LOAD_ALL_PBB } from '../../graphql';
import { DropdownMandor, Empty } from '../../containers';

class EditModal extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            mandor: '',
            pbb: [],
        	loading: false
        }
    }

    componentDidMount() {
        this.fetchData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.fetchData(nextProps);
    }


    fetchData = (props) => {
        const { loadOneMandor, loading } = props.data;
        if(loading) return 'loading';
        this.setState({
            mandor: loadOneMandor.user.id,
            pbb: loadOneMandor.pbb.map((mandor) => {
                delete mandor.__typename;
                const response = {
                    nama: mandor.name,
                    nop: mandor.nop,
                    alamat: mandor.alamat,
                    ketetapan: mandor.ketetapan
                };
                return response;
            })
        });
    };

    onSelect = (e, { value }) => this.setState({ mandor: value });

    onSelectPbb =  (data, i) => 
    this.setState(({ pbb }) => ({
        pbb: [...pbb, data]
    }));

    unSelectPbb = (index) => {
        const pbb = this.state.pbb;
        pbb.splice(index, 1);
        this.setState({ pbb });
    };

    onSubmit = (e) => {
    	e.preventDefault();
    	this.setState({ loading: true });
    	this.props.mutate({
    		variables: {
                id: this.props.listId,
    			input: {
                    mandor: this.state.mandor,
                    pbb: this.state.pbb
    			}
    		},
    		update: (store, { data: { attempUpdateMandor } }) => {
    			const { loadOneMandor } = store.readQuery({ query: LOAD_ONE_MANDOR, variables: { id: this.props.listId } });
    			loadOneMandor.push(attempUpdateMandor);
    			store.writeQuery({
    				query: LOAD_ONE_MANDOR,
    				data: { loadOneMandor }
    			});
    		}
    	}).then(() => {
            this.setState({ loading: false }); 
            this.props.handleModal()
        }).catch(err => console.log(err));
    }

    render() {
    	const { loading, mandor, pbb } = this.state;
    	const { open, handleModal } = this.props;
        return (
            <TransitionablePortal
		      open={open}
		      transition={{ animation: 'fade up', duration: 500 }}
		    >
		    <Modal 
		        size='small'
		        open={true}
                centered={false}
		    >
		    	<Modal.Header>
		    		Attemp Mandor
		        </Modal.Header>
		    	<Modal.Content>
		    		<Modal.Description style={{paddingLeft:'5px',paddingRight:'5px'}}>
		    			<Form loading={loading}>
		    				<Form.Field>
                                <label>Mandor</label>
                                <DropdownMandor data={mandor} onSelect={this.onSelect} />                  
                            </Form.Field>
                            <Form.Field>
                                <label>Selected Pbb</label>
                                <Segment inverted>
                                <List divided inverted relaxed>
                                    {!pbb.length && <List.Item>No selected pbb yet..</List.Item>}
                                    {pbb.length > 0 && pbb.map((pb, i) => (
                                        <List.Item onClick={() => this.unSelectPbb(i)}>
                                            <List.Content>
                                                <List.Header>{pb.nama}</List.Header>
                                                {pb.nop}
                                            </List.Content>
                                        </List.Item>
                                    ))}
                                </List>
                                </Segment>
                            </Form.Field>
                            <Form.Field>
                                <label>Select Data Pbb</label>
                                <Query query={LOAD_ALL_PBB}>
                                  {({ data, error, loading }) => {
                                    if(loading) return <Loader active inline='centered' />;
                                    if(error) return <p>{error}</p>;
                                    if(!loading && !data.loadAllPbb.length) return <Empty message='No Pbb Yet..' />;
                                    return (
                                        <Table basic selectable size='small'>
                                            <Table.Header>
                                              <Table.Row>
                                                <Table.HeaderCell>Name</Table.HeaderCell>
                                                <Table.HeaderCell>Nop</Table.HeaderCell>
                                                <Table.HeaderCell>Address</Table.HeaderCell>
                                              </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                             {data.loadAllPbb.map((data, i) => {
                                                delete data.__typename
                                                return (
                                                    <Table.Row onClick={() => this.onSelectPbb(data)}>
                                                        <Table.Cell>{data.nama}</Table.Cell>
                                                        <Table.Cell>{data.nop}</Table.Cell>
                                                        <Table.Cell>{data.alamat}</Table.Cell>
                                                    </Table.Row>
                                            )})}
                                            </Table.Body>
                                        </Table>
                                    );
                                  }}
                                </Query>
                            </Form.Field>
		    			</Form>
		    		</Modal.Description>
		    	</Modal.Content>
		    	<Modal.Actions>
		    	  <Button basic onClick={handleModal}>cancel</Button>
		          <Button color='blue' onClick={this.onSubmit}>Update</Button>        
		        </Modal.Actions>
		    </Modal>
		    </TransitionablePortal>
        );
    }
}

export default graphql(ATTEMP_UPDATE_MANDOR)(
    graphql(LOAD_ONE_MANDOR, {
        options: (props) => ({
            variables: { id: props.listId }
        })
    })(EditModal)
);
