import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { Button, Form, Modal, TransitionablePortal } from 'semantic-ui-react';
import { ADD_BANNER, LOAD_ALL_BANNER } from '../../graphql';
import { DropzoneImg } from '../../containers';

class AddModal extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            title: '',
        	cover: '',
            category: '',
            url: '',
            files: [],
        	loading: false
        }
    }

    onChange = (e) => this.setState({ ...this.state, [e.target.name]: e.target.value });
    onChangeCategory = (e, { value }) => this.setState({
        category: value
    });

    onPreviewDrop = (files) => {
        this.setState({
            cover: files[0],
            files: files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        });
    };

    onSubmit = (e) => {
    	e.preventDefault();
    	this.setState({ loading: true });
    	this.props.mutate({
    		variables: {
    			input: {
                    title: this.state.title,
    				image: this.state.cover,
    				category: this.state.category,
                    url: this.state.url
    			}
    		},
    		update: (store, { data: { addBanner } }) => {
    			const data = store.readQuery({ query: LOAD_ALL_BANNER });
    			data.loadAllBanner.push(addBanner);
    			store.writeQuery({
    				query: LOAD_ALL_BANNER,
    				data
    			});
    		}
    	}).then(() => {
            this.setState({ loading: false }); 
            this.props.handleModal()
        }).catch(err => console.log(err));
    }

    render() {
    	const { loading, files, category, title, url } = this.state;
    	const { open, handleModal } = this.props;
        return (
            <TransitionablePortal
		      open={open}
		      transition={{ animation: 'fade up', duration: 500 }}
		    >
		    <Modal 
		        size='small'
		        open={true}
                centered={false}
		    >
		    	<Modal.Header>
		    		Add Banner
		        </Modal.Header>
		    	<Modal.Content>
		    		<Modal.Description style={{paddingLeft:'2em',paddingRight:'2em'}}>
		    			<Form loading={loading}>
		    				<Form.Field>
                                <label>Image Banner</label>
                                <DropzoneImg files={files} onPreviewDrop={this.onPreviewDrop} />                  
                            </Form.Field>
                            <Form.Field>
                                <label>Title</label>
                                <Form.Input
                                    type='text'
                                    name='title'
                                    placeholder='Banner Title'
                                    value={title}
                                    onChange={this.onChange} 
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Category</label>
                                <Form.Select 
                                    options={[
                                        { key: 0, text: 'Promo', value: 'promotion' },
                                        { key: 1, text: 'Iklan', value: 'advertise'}
                                    ]}
                                    value={category}
                                    onChange={this.onChangeCategory}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Url</label>
                                <Form.Input
                                    name='url'
                                    placeholder='url'
                                    value={url}
                                    onChange={this.onChange} 
                                />
                            </Form.Field>
		    			</Form>
		    		</Modal.Description>
		    	</Modal.Content>
		    	<Modal.Actions>
		    	  <Button basic onClick={handleModal}>cancel</Button>
		          <Button color='blue' onClick={this.onSubmit}>Save</Button>        
		        </Modal.Actions>
		    </Modal>
		    </TransitionablePortal>
        );
    }
}

export default graphql(ADD_BANNER)(AddModal);
