import React from 'react';

const DelConfirm = ({ message }) => {
    return (
    	<div style={{textAlign: 'center'}}>
	        <div id='ad-delconfirm'/>
	        <span style={{
	        	top: '2em',
	        	textAlign: 'center',
	        	fontSize: '20px',
	        	fontWeight: '600'
	        }}>
	        	{message}
	        </span>
        </div>
    );
};

DelConfirm.displayName = 'DelConfirm';

export { DelConfirm };
