import gql from 'graphql-tag';

export const ADD_BANNER = gql`
mutation ADD_BANNER($input: InputBanner!){
	addBanner(input: $input) {
		id
		image
		title
		url
		category
	}
}
`;

export const UPDATE_BANNER = gql`
mutation UPDATE_BANNER($id: ID!, $input: InputBanner!){
	updateBanner(id: $id, input: $input) {
		id
		image
		title
		url
		category
	}
}
`;

export const REMOVE_BANNER = gql`
mutation REMOVE_BANNER($id: ID!){
	removeBanner(id: $id) {
		id
	}
}
`;


export const ADD_HOROSKOP = gql`
mutation ADD_HOROSKOP($input: InputHoroskop!){
	addHoroskop(input: $input) {
		id
		title
		slug
		image
		content
		descriptions
	}
}
`;

export const UPDATE_HOROSKOP = gql`
mutation UPDATE_HOROSKOP($id: ID!, $input: InputHoroskop!){
	updateHoroskop(id: $id, input: $input) {
		id
		title
		slug
		image
		content
		descriptions
	}
}
`;

export const REMOVE_HOROSKOP = gql`
mutation REMOVE_HOROSKOP($id: ID!){
	removeHoroskop(id: $id) {
		id
	}
}
`;

// Mandors

export const ATTEMP_MANDOR = gql`
mutation ATTEMP_MANDOR($input: InputMandor!){
	attempMandor(input: $input) {
		id
		user {
			name
			mandor
		}
		pbb {
			nop
			name
			address
			ammount
		}
	}
}
`;

export const ATTEMP_UPDATE_MANDOR = gql`
mutation ATTEMP_UPDATE_MANDOR($id: ID!, $input: InputMandor!){
	attempUpdateMandor(id: $id, input: $input) {
		id
		user {
			id
			name
			mandor
		}
		pbb {
			nop
			name
			address
			ammount
		}
	}
}
`;


// Users

export const UPDATE_USER_ROLE = gql`
mutation UPDATE_USER_ROLE($id: ID!, $input: InputUserRole!){
	updateUserRole(id: $id, input: $input){
		id
		name
		mandor
		merchant
		createdAt
	}
}
`
