import React from 'react';

const Empty = ({ message }) => {
    return (
    	<div style={{textAlign: 'center'}}>
	        <div className='empty-illust' />
	        <span className='empty-span'>
	        	{message}
	        </span>
        </div>
    );
};

Empty.displayName = 'Empty';

export { Empty };
