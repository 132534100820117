import React from 'react';
import PropTypes from 'prop-types';
import { Uploads } from '../components';
import HelmetComponent from '../helpers/HelmetComponent';

const UploadsPage = ({ className }) => {
    return (
    	<div>
    		<HelmetComponent title='Uploads' />
        	<Uploads />
        </div>
    );
};

UploadsPage.displayName = 'UploadsPage';

UploadsPage.propTypes = {
    className: PropTypes.string,
};

export { UploadsPage };
