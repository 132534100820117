import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Card, Grid, Icon, Loader, Menu, Popup, Transition } from 'semantic-ui-react';
import { LOAD_ALL_BANNER } from '../graphql';
import { Item, MenuProvider } from 'react-contexify';
import { ContextMenu, Empty, Main } from '../containers';
import AddModal from './ad-banner/AddModal';
import EditModal from './ad-banner/EditModal';
import RemoveModal from './ad-banner/RemoveModal';

const style = {
    selected: {
        backgroundColor: '#e3f2fd',
        color: '#fff'
    }
};

class Banners extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    constructor(props) {
    	super(props);
    	this.state = {
    		openAdd: false,
            openEdit: false,
            openRemove: false,
            selected: ''
    	}
    }

    onSelect = (e, id) => {
        const selected = this.state.selected === id ? '' : id;
        this.setState((prevState) => ({
            selected
        }));
    };

    handleAddModal = () => this.setState((prevState) => ({
        openAdd: !prevState.openAdd
    }));

    handleEditModal = (id) => this.setState((prevState) => ({
        openEdit: !prevState.openEdit
    }));

    handleRemoveModal = (id) => this.setState((prevState) => ({
        openRemove: !prevState.openRemove
    }));

    render() {
    	const { openAdd, openEdit, openRemove, selected } = this.state;
        return (
            <Main label='Banners' contextMenu='banners_page' navigation={
                <Menu.Menu position='right' style={{position: 'fixed', right: '20px'}}>

                <Transition
                      visible={!!selected}
                      animation='slide left'
                      duration={500}
                    >
                       <Menu.Item>
                            <Popup
                                content='Edit Banner'
                                trigger={
                                 <span onClick={() => this.handleEditModal(selected)}>
                                    <svg style={{ width: '22px', height: 'auto' }} enableBackground="new 0 0 512.002 512.002" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m512 98.91c0-14.359-5.585-27.857-15.756-38.028l-45.138-45.137c-9.996-10.007-23.854-15.744-38.016-15.744s-28.02 5.737-38.028 15.756l-10.927 10.927c-4.55 4.55-4.55 11.904 0 16.454s11.904 4.55 16.454 0l10.927-10.927c11.345-11.357 31.791-11.357 43.136 0l45.137 45.137c5.76 5.76 8.937 13.417 8.937 21.562s-3.165 15.802-8.937 21.574l-45.836 45.836c-4.55 4.55-4.515 11.939 0.035 16.489l6.772 6.784c0.908 0.896 1.42 2.141 1.42 3.409 0 3.921-5.457 6.191-8.227 3.409l-118.35-118.35c-0.908-0.908-1.42-2.153-1.42-3.421 0-3.933 5.457-6.179 8.227-3.409l76.637 76.637c4.55 4.55 11.904 4.55 16.454 0s4.55-11.904 0-16.454l-76.625-76.625c-5.225-5.236-12.463-8.239-19.875-8.239-15.488 0-28.09 12.602-28.09 28.09 0 6.714 2.583 13.231 6.958 18.316l-259.55 259.55c-4.55 4.55-4.55 11.904 0 16.454l69.818 69.818c2.269 2.269 5.248 3.409 8.227 3.409s5.958-1.14 8.227-3.409c4.55-4.55 4.55-11.904 0-16.454l-61.591-61.591 251.18-251.18 88.273 88.273-257.14 257.13-92.881 23.215-18.607-18.607 12.369-49.455c1.571-6.237-2.223-12.556-8.471-14.115-6.249-1.606-12.544 2.234-14.103 8.471l-23.273 93.093c-1.001 3.968 0.174 8.157 3.06 11.055 2.199 2.199 5.178 3.398 8.227 3.398 0.943 0 1.885-0.116 2.816-0.349l139.64-34.909c2.048-0.512 3.921-1.571 5.411-3.06l259.55-259.55c5.073 4.387 11.578 6.959 18.316 6.959 15.488 0 28.09-12.602 28.09-28.09 0-6.726-2.479-13.324-6.865-18.409l37.655-37.667c10.159-10.16 15.756-23.659 15.756-38.018z"/>
                                            <path d="m322.41 154.68c-4.55-4.55-11.904-4.55-16.454 0l-197.82 197.82c-4.55 4.55-4.55 11.904 0 16.454 2.269 2.269 5.248 3.409 8.227 3.409s5.958-1.14 8.227-3.409l197.82-197.82c4.549-4.55 4.549-11.904 0-16.454z"/>
                                            <path d="m357.32 189.59c-4.55-4.55-11.904-4.55-16.454 0l-197.82 197.82c-4.55 4.55-4.55 11.904 0 16.454 2.269 2.269 5.248 3.409 8.227 3.409s5.958-1.14 8.227-3.409l197.82-197.82c4.55-4.55 4.55-11.904 0-16.454z"/>
                                    </svg>
                                 </span>
                                }
                                position='bottom center'
                                inverted
                                size='tiny'
                             />
                             <Popup
                                content='Remove Banner'
                                trigger={
                                 <span onClick={() => this.handleRemoveModal(selected)} style={{marginLeft: '20px'}}>
                                    <svg style={{ width: '22px', height: 'auto' }} viewBox="-40 0 427 427" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m232.4 154.7c-5.5234 0-10 4.4766-10 10v189c0 5.5195 4.4766 10 10 10 5.5234 0 10-4.4805 10-10v-189c0-5.5234-4.4766-10-10-10z"/>
                                    <path d="m114.4 154.7c-5.5234 0-10 4.4766-10 10v189c0 5.5195 4.4766 10 10 10 5.5234 0 10-4.4805 10-10v-189c0-5.5234-4.4766-10-10-10z"/>
                                    <path d="m28.398 127.12v246.38c0 14.562 5.3398 28.238 14.668 38.051 9.2852 9.8398 22.207 15.426 35.73 15.449h189.2c13.527-0.023438 26.449-5.6094 35.73-15.449 9.3281-9.8125 14.668-23.488 14.668-38.051v-246.38c18.543-4.9219 30.559-22.836 28.078-41.863-2.4844-19.023-18.691-33.254-37.879-33.258h-51.199v-12.5c0.058593-10.512-4.0977-20.605-11.539-28.031-7.4414-7.4219-17.551-11.555-28.062-11.469h-88.797c-10.512-0.085938-20.621 4.0469-28.062 11.469-7.4414 7.4258-11.598 17.52-11.539 28.031v12.5h-51.199c-19.188 0.003906-35.395 14.234-37.879 33.258-2.4805 19.027 9.5352 36.941 28.078 41.863zm239.6 279.88h-189.2c-17.098 0-30.398-14.688-30.398-33.5v-245.5h250v245.5c0 18.812-13.301 33.5-30.398 33.5zm-158.6-367.5c-0.066407-5.207 1.9805-10.219 5.6758-13.895 3.6914-3.6758 8.7148-5.6953 13.926-5.6055h88.797c5.2109-0.089844 10.234 1.9297 13.926 5.6055 3.6953 3.6719 5.7422 8.6875 5.6758 13.895v12.5h-128zm-71.199 32.5h270.4c9.9414 0 18 8.0586 18 18s-8.0586 18-18 18h-270.4c-9.9414 0-18-8.0586-18-18s8.0586-18 18-18z"/>
                                    <path d="m173.4 154.7c-5.5234 0-10 4.4766-10 10v189c0 5.5195 4.4766 10 10 10 5.5234 0 10-4.4805 10-10v-189c0-5.5234-4.4766-10-10-10z"/>
                                    </svg>
                                 </span>
                                }
                                position='bottom center'
                                inverted
                                size='tiny'
                            />
                       </Menu.Item>
                    </Transition>

                    <Menu.Item>
                        <svg style={{ width: '22px', height: 'auto' }} enableBackground="new 0 0 513.829 513.829" version="1.1" viewBox="0 0 513.83 513.83" xmlns="http://www.w3.org/2000/svg">
                                <path d="m462.63 0.915h-413.26c-27.428 0-49.371 21.942-49.371 49.37v415.09c0 25.6 21.943 47.543 49.371 47.543h415.09c27.429 0 49.372-21.943 49.372-49.372v-413.26c-1.829-27.428-23.772-49.37-51.201-49.37zm12.801 462.63c0 7.314-5.485 12.8-12.8 12.8h-413.26c-7.314 0-12.8-5.485-12.8-12.8v-413.26c0-7.314 5.486-12.8 12.8-12.8h415.09c5 .485 0 10.971 5.486 10.971 12.8v413.26z"/>
                                <path d="m429.72 143.54h-204.8c-10.971 0-18.286 7.314-18.286 18.286s7.314 18.286 18.286 18.286h206.63c10.971 0 18.286-7.314 18.286-18.286s-9.143-18.286-20.114-18.286z"/>
                                <path d="m429.72 255.08h-204.8c-10.971 0-18.286 7.314-18.286 18.286s7.314 18.286 18.286 18.286h206.63c10.971 0 18.286-7.314 18.286-18.286-1.829-10.971-9.143-18.286-20.114-18.286z"/>
                                <path d="m429.72 364.8h-204.8c-10.971 0-18.286 7.314-18.286 18.286s7.314 18.286 18.286 18.286h206.63c10.971 0 18.286-7.314 18.286-18.286s-9.143-18.286-20.114-18.286z"/>
                                <path d="m133.49 106.97c-27.429 0-51.2 21.943-51.2 51.2s21.943 51.2 51.2 51.2 51.2-21.943 51.2-51.2-23.772-51.2-51.2-51.2zm0 64c-7.314 0-14.629-5.486-14.629-14.629 0-7.314 5.486-14.629 14.629-14.629 7.314 0 14.629 5.486 14.629 14.629s-7.316 14.629-14.629 14.629z"/>
                                <path d="m133.49 218.51c-27.429 0-51.2 21.943-51.2 51.2 0 27.429 21.943 51.2 51.2 51.2s51.2-21.943 51.2-51.2-23.772-51.2-51.2-51.2zm0 64c-7.314 0-14.629-5.485-14.629-14.629s5.486-14.629 14.629-14.629c7.314 0 14.629 5.485 14.629 14.629s-7.316 14.629-14.629 14.629z"/>
                                <path d="m133.49 330.06c-27.429 0-51.2 21.943-51.2 51.2 0 27.429 21.943 51.2 51.2 51.2s51.2-21.943 51.2-51.2-23.772-51.2-51.2-51.2zm0 64.001c-7.314 0-14.629-5.485-14.629-14.629 0-7.314 5.486-14.629 14.629-14.629 7.314 0 14.629 5.485 14.629 14.629 0 7.315-7.316 14.629-14.629 14.629z"/>
                        </svg>
                    </Menu.Item>

                </Menu.Menu>
            }>
                <AddModal open={openAdd} handleModal={this.handleAddModal} />
                {!!openEdit && <EditModal open={openEdit} handleModal={this.handleEditModal} rowId={selected} />}
                {!!openRemove && <RemoveModal open={openRemove} handleModal={this.handleRemoveModal} rowId={selected} />}
            	<Grid style={{marginTop: '2em'}}>
            		<Grid.Row>
            			<Grid.Column mobile={16} computer={16}>
            				<Query query={LOAD_ALL_BANNER}>
            					{({ data, error, loading }) => {
            						if(loading) return <Loader active inline='centered' />;
                                	if(error) return <p>{error}</p>;
                                	if(!loading && !data.loadAllBanner.length) return <Empty message='No Banner Yet..' />;
                                	return (
                                        <MenuProvider id='banners_card'>
                                		<Grid>
			            					<Grid.Row>
			            						{data.loadAllBanner.map((banner, i) => (
			            							<Grid.Column mobile={16} computer={4}>
        			            							<Card 
                                                                fluid 
                                                                style={selected === banner.id ? style.selected : null}
                                                                onClick={(e) => this.onSelect(e, banner.id)} 
                                                                onContextMenu={(e) => this.onSelect(e, banner.id)}
                                                            >
        													    <div style={{
                                                                    height: '170px', 
                                                                    width: '100%', 
                                                                    backgroundImage: `url(${banner.image})`,
                                                                    backgroundSize: 'cover'
                                                                }}/>
                                                                <Card.Content>
                                                                    <Card.Header>
                                                                        <Icon name='picture' />
                                                                        <span>{banner.title}</span>
                                                                    </Card.Header>
                                                                </Card.Content>
        													</Card>
				            						</Grid.Column>
			            						))}
                                                <ContextMenu id='banners_card'>
                                                    <Item onClick={this.handleEditModal}>
                                                        <Icon name='edit outline' />
                                                        Edit Banner
                                                    </Item>
                                                    <Item onClick={this.handleRemoveModal}>
                                                        <Icon name='trash alternate outline' />
                                                        Remove Banner
                                                    </Item>
                                                </ContextMenu>
                                                
			            					</Grid.Row>
			            				</Grid>
                                    </MenuProvider>
                                	)
            					}}
            				</Query>
            			</Grid.Column>
            		</Grid.Row>
            	</Grid>
                <ContextMenu id='banners_page'>
                   <Item onClick={this.handleAddModal}>
                     <Icon name='plus' />
                     <span>Create Banner</span>
                   </Item>
                </ContextMenu>
            </Main>
        );
    }
}

export { Banners };
