import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Container, Card, Grid, List, Image, Loader, Header } from 'semantic-ui-react';
import { LOAD_ALL_HOROSKOP } from '../graphql';
import { Empty, DisplayEditor } from '../containers';

class Home extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //     	title: ''
    //     }
    // }
  

    render() {
        return (
            <Grid style={{marginTop: '1em'}}>
        		<Grid.Row>
        			<Grid.Column mobile={16} computer={16}>
        			<Container>
        				<Header as='h3' textAlign='center'>Horoskop 19 Nov - 3 Des 2019</Header>
        				<Query query={LOAD_ALL_HOROSKOP}>
        					{({ data, error, loading }) => {
        						if(loading) return <Loader active inline='centered' />;
                            	if(error) return <p>{error}</p>;
                            	if(!loading && !data.loadAllHoroskop.length) return <Empty message='No Horoskops Yet..' />;
                            	return (
                        		<Grid>
	            					<Grid.Row>
	            						{data.loadAllHoroskop.map((horoskop, i) => (
	            							<Grid.Column mobile={16} computer={8}>
	            								<Card fluid style={{boxShadow: 'none'}}>
			            							<Grid>
			            								<Grid.Row>
			            									<Grid.Column mobile={6} computer={6} style={{paddingTop: '3em'}}>
			            										<Image src={horoskop.image} ui={false} />
			            									</Grid.Column>
			            									<Grid.Column mobile={10} computer={10}>
			            										<Card.Content style={{ marginTop: '1em' }}>
			            											<Card.Header>
			            												<Header as='h5'>{horoskop.title}</Header>
			            											</Card.Header>
			            											<Card.Description style={{marginTop: '10px', fontSize: '12px'}}>
			            												{horoskop.descriptions}
			            											</Card.Description>
			            										</Card.Content>
			            									</Grid.Column>
			            								</Grid.Row>
			            							</Grid>
		            							</Card>
		            						</Grid.Column>
	            						))}
	            					</Grid.Row>
	            				</Grid>
                            	)
        					}}
        				</Query>
        			</Container>
        			</Grid.Column>
        		</Grid.Row>
        	</Grid>
        );
    }
}

export { Home };
