import React from 'react';
import PropTypes from 'prop-types';
import { Home } from '../fronts';

const HomePage = ({ className }) => {
    return (
        <Home />
    );
};

HomePage.displayName = 'HomePage';

HomePage.propTypes = {
    className: PropTypes.string,
};

export { HomePage };
