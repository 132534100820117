import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { Button, Modal, TransitionablePortal } from 'semantic-ui-react';
import { LOAD_ALL_HOROSKOP, REMOVE_HOROSKOP } from '../../graphql';
import { DelConfirm } from '../../containers';

const RemoveModal = ({ handleModal, rowId, open }) => {
	const [removeHoroskop] = useMutation(REMOVE_HOROSKOP, {
		update(cache, { data: { removeHoroskop }}) {
			const { loadAllHoroskop } = cache.readQuery({ query: LOAD_ALL_HOROSKOP });
			const horoskops = loadAllHoroskop.filter(horoskop => horoskop.id !== removeHoroskop.id);
			cache.writeQuery({
				query: LOAD_ALL_HOROSKOP,
				data: { loadAllHoroskop: horoskops }
			})
		}
	});
    return (
    	<TransitionablePortal
	      open={open}
	      transition={{ animation: 'fade down', duration: 500 }}
	    >
    	<Modal size='small' open={true} centered={false}>
          <Modal.Content>
            <DelConfirm message='Are you sure, you want delete this horoskop' />
            <div style={{marginTop: '2em', textAlign:'center'}}>
              <Button color='orange' onClick={() => handleModal(rowId)}>No</Button>
              <Button
                color='red'
                icon='trash'
                labelPosition='right'
                content='Yes'
                onClick={e => {
                  removeHoroskop({ variables: { id: rowId } });
                  handleModal(rowId);
                }}
              />
            </div>
          </Modal.Content>
        </Modal>
        </TransitionablePortal>
    );
};

RemoveModal.displayName = 'RemoveModal';

RemoveModal.propTypes = {
    className: PropTypes.string,
};

export default RemoveModal;
