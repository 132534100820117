import React from 'react';
import PropTypes from 'prop-types';
import { Banners } from '../components';
import HelmetComponent from '../helpers/HelmetComponent';

const BannersPage = ({ className }) => {
    return (
    	<div>
    		<HelmetComponent title='Banners' />
        	<Banners />
        </div>
    );
};

BannersPage.displayName = 'BannersPage';

BannersPage.propTypes = {
    className: PropTypes.string,
};

export { BannersPage };
