import React from 'react';
import PropTypes from 'prop-types';
import { Users } from '../components';
import HelmetComponent from '../helpers/HelmetComponent';

const UsersPage = ({ className }) => {
    return (
    	<div>
	    	<HelmetComponent title='Users' />
	        <Users />
        </div>
    );
};

UsersPage.displayName = 'UsersPage';

UsersPage.propTypes = {
    className: PropTypes.string,
};

export { UsersPage };
