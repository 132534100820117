import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Menu, Icon, Input } from 'semantic-ui-react';
import { MenuProvider } from 'react-contexify';
import SideNavbar from './SideNavbar';
import avatar from '../../libs/icons/man.svg';
import logo from '../../libs/logo.png';

class Navbar extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    // constructor(props) {
    //     super(props);
    // }

    render() {
    	const { children, location } = this.props;
        return (
        	<div>
        		<MenuProvider id='body'>
        		<nav>
		            <Menu size='huge' fixed='top' secondary className='sae-navbar'>
		            	<Grid style={{width: '100%', paddingLeft: '10px', paddingRight: '0px'}}>
		            		<Grid.Row>
		            			<Grid.Column computer={3}>
		            				<Menu.Item className='banner'>
				            			Sae
				            			<img src={logo} alt='logo' style={{width: '50px', height: 'auto'}} />
				            			Manages
				            		</Menu.Item>
		            			</Grid.Column>
		            			<Grid.Column computer={10}>
		            				<Menu.Item style={{marginTop: '5px'}}>
			            				<Input icon='search' className='pay_search' placeholder='Search' />
			            			</Menu.Item>
		            			</Grid.Column>
		            			<Grid.Column computer={3}>
			            			<Menu.Item style={{ float: 'right' }}>
			            				<span style={{margin: '0 1.5em 0 0'}}>
			            					<svg style={{ width: '26px', height: '26px' }} viewBox="-20 0 512 512" xmlns="http://www.w3.org/2000/svg">
							                <path d="m426.49 348.88h-0.03125c-14.723-0.007812-23.035-7.6484-27.793-25.531-4.8633-18.289-5.0273-44.812-5.1992-72.895-0.20703-34.023-0.44141-72.586-8.8984-107.07-1-4.0781-5.1211-6.5742-9.1992-5.5742-4.0742 1-6.5703 5.1172-5.5742 9.1953 8.0352 32.75 8.2617 70.363 8.4648 103.55 0.17969 29.078 0.34375 56.543 5.707 76.707 2.332 8.7695 5.6758 15.977 9.9961 21.625h-316.86c4.3203-5.6484 7.6641-12.855 9.9961-21.625 5.3633-20.164 5.5312-47.629 5.707-76.707 0.26953-44.395 0.57812-94.711 18.742-133.22 20.445-43.348 61-64.422 123.98-64.422 34.59 0 62.895 6.5 84.125 19.324 18.41 11.117 31.926 26.918 41.32 48.305 1.6875 3.8438 6.1758 5.5938 10.02 3.9023 3.8438-1.6875 5.5938-6.1719 3.9062-10.02-10.551-24.016-26.492-42.59-47.383-55.207-13.336-8.0547-28.996-13.863-46.832-17.402-1.7969-23.348-21.355-41.805-45.156-41.805-23.801 0-43.363 18.461-45.148 41.812-17.016 3.3711-32.059 8.8047-44.969 16.285-20.773 12.039-36.797 29.785-47.625 52.738-19.594 41.543-19.914 93.645-20.191 139.61-0.17188 28.082-0.33203 54.605-5.1992 72.895-4.7578 17.883-13.07 25.523-27.793 25.531h-0.03125c-24.582 0-44.582 20-44.582 44.586 0 24.582 20 44.582 44.582 44.582h48.129c4.1992 0 7.6055-3.4062 7.6055-7.6055s-3.4062-7.6016-7.6055-7.6016h-48.129c-16.195 0-29.375-13.18-29.375-29.375 0-16.195 13.176-29.371 29.371-29.375h381.92c16.191 0.003906 29.367 13.18 29.367 29.375 0 16.195-13.18 29.375-29.375 29.375h-303.36c-4.1992 0-7.6016 3.4023-7.6016 7.6016s3.4023 7.6055 7.6016 7.6055h31.215c3.8477 41.422 38.785 73.957 81.195 73.957 42.406 0 77.344-32.535 81.191-73.957h109.76c24.582 0 44.582-20 44.582-44.582 0-24.586-20-44.586-44.582-44.586zm-190.95-333.67c14.562 0 26.742 10.402 29.504 24.164-9.3359-1.1016-19.164-1.6758-29.504-1.6758-10.34 0-20.168 0.57031-29.5 1.6719 2.7617-13.762 14.938-24.16 29.5-24.16zm0 481.59c-34.016 0-62.121-25.734-65.906-58.75h131.81c-3.7852 33.016-31.891 58.75-65.902 58.75z"/>
							                <path d="m357.63 47.723c0.13672 0.074219 13.676 7.8008 26.996 25.75 17.844 24.039 26.887 54.07 26.887 89.262 0 4.1992 3.4062 7.6055 7.6055 7.6055 4.1992 0 7.6055-3.4062 7.6055-7.6055 0-48.445-16.543-80.602-30.422-99.043-15.25-20.266-30.645-28.91-31.293-29.27-3.668-2.0234-8.2695-0.69531-10.309 2.9609-2.0391 3.6602-0.72266 8.2852 2.9297 10.34z"/>
							                <path d="m415 50.512c1.2227 0.80078 29.969 20.094 29.969 68.633 0 4.1992 3.4062 7.6016 7.6055 7.6016s7.6016-3.4023 7.6016-7.6016c0-57.25-35.465-80.48-36.977-81.441-3.5273-2.2461-8.1914-1.207-10.457 2.3125-2.2656 3.5156-1.2461 8.2109 2.2578 10.496z"/>
							                <path d="m51.949 170.34c4.1992 0 7.6055-3.4062 7.6055-7.6055 0-35.191 9.0469-65.223 26.887-89.262 13.238-17.836 26.695-25.578 26.992-25.75 3.6602-2.0352 4.9883-6.6523 2.9609-10.32-2.0352-3.6758-6.6602-5.0078-10.336-2.9805-0.64844 0.35938-16.043 9.0039-31.293 29.27-13.875 18.441-30.418 50.598-30.418 99.043 0 4.1992 3.4023 7.6055 7.6016 7.6055z"/>
							                <path d="m18.496 126.75c4.1992 0 7.6016-3.4023 7.6016-7.6016 0-48.5 28.703-67.805 29.969-68.629 3.5156-2.2617 4.5469-6.9453 2.3008-10.477-2.2539-3.543-6.9531-4.5859-10.5-2.332-1.5078 0.96094-36.977 24.188-36.977 81.438 0 4.1992 3.4062 7.6016 7.6055 7.6016z"/>
							                </svg>
			            				</span>
			            				<span style={{margin: '0 1.5em 0 0'}}>
											<svg style={{ width: '26px', height: '26px' }} enableBackground="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
											<path d="m391.99 10h-271.99c-11.046 0-20 8.954-20 20v452c0 11.046 8.954 20 20 20h271.99c11.046 0 20-8.954 20-20v-452c1e-3 -11.046-8.954-20-19.999-20z" fill="#C4EFFF"/>
											<rect x="100.01" y="64" width="311.99" height="384" fill="#EAF9FF"/>
											<rect x="284.37" y="112" width="64" height="64" fill="#A8E7FF"/>
											<rect x="284.37" y="224" width="64" height="64" fill="#E5F8D2"/>
											<rect x="284.37" y="336" width="64" height="64" fill="#FFCFCE"/>
											<rect x="172.33" y="112" width="64" height="64" fill="#E5F8D2"/>
											<rect x="172.33" y="224" width="64" height="64" fill="#F9CC9F"/>
											<rect x="172.33" y="336" width="64" height="64" fill="#C1EE92"/>
											<path d="m10 63.987v92c0 11.046 8.954 20 20 20h92c11.046 0 20-8.954 20-20v-92c0-11.046-8.954-20-20-20h-92c-11.046 0-20 8.954-20 20z" fill="#E5F8D2"/>
											<path d="M142,75l-59.5,59.5l-29.25-29.25L10,148.5v7.487c0,11.046,8.954,20,20,20h92c11.046,0,20-8.954,20-20  V75z" fill="#C1EE92"/>
											<path d="m354 320v108c0 11.046 8.954 20 20 20h108c11.046 0 20-8.954 20-20v-108c0-11.046-8.954-20-20-20h-108c-11.046 0-20 8.954-20 20z" fill="#FE8F8D"/>
											<path d="m419.08 404.41 35.105-20.236c7.836-4.517 7.837-15.824 3e-3 -20.343l-35.105-20.247c-7.827-4.515-17.607 1.135-17.607 10.171v40.483c-1e-3 9.034 9.777 14.683 17.604 10.172z" fill="#fff"/>
											<path d="m366.33 74c2.63 0 5.21-1.07 7.069-2.93 1.86-1.86 2.931-4.44 2.931-7.07s-1.07-5.21-2.931-7.07c-1.859-1.86-4.439-2.93-7.069-2.93-2.641 0-5.21 1.07-7.07 2.93-1.87 1.86-2.93 4.44-2.93 7.07s1.06 5.21 2.93 7.07c1.86 1.86 4.429 2.93 7.07 2.93z"/>
											<path d="m358.37 112c0-5.523-4.478-10-10-10h-64c-5.522 0-10 4.477-10 10v64c0 5.523 4.478 10 10 10h64c5.522 0 10-4.477 10-10v-64zm-20 54h-44v-44h44v44z"/>
											<path d="m358.37 288v-64c0-5.523-4.478-10-10-10h-64c-5.522 0-10 4.477-10 10v64c0 5.523 4.478 10 10 10h64c5.523 0 10-4.477 10-10zm-20-10h-44v-44h44v44z"/>
											<path d="m284.37 326c-5.522 0-10 4.477-10 10v64c0 5.523 4.478 10 10 10s10-4.477 10-10v-54h22c5.522 0 10-4.477 10-10s-4.478-10-10-10h-32z"/>
											<path d="m246.33 112c0-5.523-4.478-10-10-10s-10 4.477-10 10v54h-54c-5.522 0-10 4.477-10 10s4.478 10 10 10h64c5.522 0 10-4.477 10-10v-64z"/>
											<path d="m246.33 224c0-5.523-4.478-10-10-10h-64c-5.522 0-10 4.477-10 10v64c0 5.523 4.478 10 10 10h64c5.522 0 10-4.477 10-10v-64zm-20 54h-44v-44h44v44z"/>
											<path d="m236.33 326h-64c-5.522 0-10 4.477-10 10v64c0 5.523 4.478 10 10 10h64c5.522 0 10-4.477 10-10v-64c0-5.523-4.478-10-10-10zm-10 64h-44v-44h44v44z"/>
											<path d="m248.66 469.2c-1.86 1.86-2.931 4.43-2.931 7.07 0 2.63 1.07 5.2 2.931 7.07 1.859 1.86 4.439 2.93 7.069 2.93s5.21-1.07 7.07-2.93c1.86-1.87 2.93-4.44 2.93-7.07 0-2.64-1.069-5.21-2.93-7.07-1.86-1.87-4.44-2.93-7.07-2.93s-5.209 1.06-7.069 2.93z"/>
											<path d="m152 155.99v-81.987h168c5.522 0 10-4.477 10-10s-4.478-10-10-10h-169.72c-4.124-11.646-15.243-20.013-28.284-20.013h-11.994v-3.987c0-5.514 4.486-10 10-10h271.99c5.514 0 10 4.486 10 10v226c0 5.523 4.478 10 10 10s10-4.477 10-10v-226c0-16.542-13.458-30-30-30h-271.99c-16.542 0-30 13.458-30 30v3.987h-60.006c-16.542 0-30 13.458-30 30v92c0 16.542 13.458 30 30 30h92c16.542 0 30-13.458 30-30zm-122-102h92c5.514 0 10 4.486 10 10v6.871l-49.5 49.5-22.179-22.179c-3.906-3.905-10.236-3.905-14.143 0l-26.178 26.178v-60.37c0-5.514 4.486-10 10-10zm-10 102v-3.633c0.555-0.366 1.082-0.794 1.571-1.283l31.679-31.679 22.179 22.179c3.906 3.905 10.236 3.905 14.143 0l42.428-42.428v56.844c0 5.514-4.486 10-10 10h-92c-5.514 0-10-4.486-10-10z"/>
											<path d="m482 290h-108c-16.542 0-30 13.458-30 30v108c0 16.542 13.458 30 30 30h27.994v24c0 5.514-4.486 10-10 10h-271.99c-5.514 0-10-4.486-10-10v-24h211.34c5.522 0 10-4.477 10-10s-4.478-10-10-10h-211.34v-214c0-5.523-4.478-10-10-10s-10 4.477-10 10v258c0 16.542 13.458 30 30 30h271.99c16.542 0 30-13.458 30-30v-24h60.006c16.542 0 30-13.458 30-30v-108c0-16.542-13.458-30-30-30zm10 138c0 5.514-4.486 10-10 10h-108c-5.514 0-10-4.486-10-10v-108c0-5.514 4.486-10 10-10h108c5.514 0 10 4.486 10 10v108z"/>
											<path d="m458.06 201.65c5.522 0 10-4.477 10-10v-79.647c0-5.523-4.478-10-10-10s-10 4.477-10 10v79.647c0 5.523 4.477 10 10 10z"/>
											<path d="m458.06 239.71c2.63 0 5.21-1.07 7.07-2.93 1.87-1.86 2.93-4.44 2.93-7.07s-1.06-5.21-2.93-7.07c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.069 2.93c-1.86 1.86-2.931 4.44-2.931 7.07s1.07 5.21 2.931 7.07c1.859 1.86 4.439 2.93 7.069 2.93z"/>
											<path d="m54 230.98c-5.522 0-10 4.477-10 10v68.866c0 5.523 4.478 10 10 10s10-4.477 10-10v-68.866c0-5.523-4.478-10-10-10z"/>
											<path d="m459.18 355.17-35.104-20.248c-6.808-3.926-14.934-3.923-21.737 7e-3 -6.804 3.931-10.866 10.968-10.866 18.826v40.482c0 7.856 4.062 14.894 10.864 18.825 3.405 1.967 7.141 2.951 10.877 2.951 3.729 0 7.458-0.98 10.857-2.94l35.104-20.235c6.814-3.928 10.883-10.969 10.884-18.834 1e-3 -7.864-4.067-14.905-10.879-18.834zm-9.993 20.342-35.104 20.235c-0.577 0.332-1.163 0.331-1.74-1e-3 -0.577-0.334-0.87-0.841-0.87-1.508v-40.482c0-0.667 0.293-1.174 0.87-1.508 0.289-0.167 0.58-0.25 0.871-0.25s0.581 0.083 0.87 0.25l35.104 20.248c0.578 0.333 0.871 0.841 0.871 1.508 0 0.666-0.293 1.174-0.872 1.508z"/>
											</svg>
			            				</span>
			            				<span>
			            					<img src={avatar} height='30' width='30' alt='avatar' />
				            				{/*<Button inverted basic compact as={Link} to='/account/transactions'>
				            					Transactions
				            				</Button>*/}
			            				</span>
			            			</Menu.Item>
		            			</Grid.Column>
		            		</Grid.Row>
		            	</Grid>
		            	
		            </Menu>
	            </nav>
	            <main style={{ padding: '7px' }}>
	            	<Grid>
	            		<Grid.Row>
	            			<Grid.Column computer={3}>
	            				<SideNavbar location={location} />
	            			</Grid.Column>
	            			<Grid.Column computer={12}>
	            				{children}
	            			</Grid.Column>
	            		</Grid.Row>
	            	</Grid>
	            </main>
	            </MenuProvider>
            </div>
        );
    }
}

export { Navbar };
