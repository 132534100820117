import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Root } from './setup';
import configureStore from './store/redux';
import configGraphql from './store/graphql';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css';

const store = configureStore();
const history = createBrowserHistory();
const supportsHistory = 'pushState' in window.history;

ReactDOM.render(
	<Root 
		client={configGraphql} 
		history={history} 
		store={store} 
		supportsHistory={supportsHistory} 
	/>, 
	document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
