import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu } from 'semantic-ui-react';
import { Item, MenuProvider } from 'react-contexify';
import { ContextMenu, Empty, Main } from '../containers';

class Uploads extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <Main label='Uploads' contextMenu='uploads_page' navigation={
                <Menu.Menu position='right' style={{position: 'fixed', right: '30px'}}>
                    <Menu.Item>
                        <svg style={{ width: 'auto', height: '24px' }} enableBackground="new 0 0 309.267 309.267" version="1.1" viewBox="0 0 309.27 309.27" xmlns="http://www.w3.org/2000/svg">
                            <path d="M38.658,0h164.23l87.049,86.711v203.227c0,10.679-8.659,19.329-19.329,19.329H38.658   c-10.67,0-19.329-8.65-19.329-19.329V19.329C19.329,8.65,27.989,0,38.658,0z" fill="#E2574C"/>
                            <path d="m289.66 86.981h-67.372c-10.67 0-19.329-8.659-19.329-19.329v-67.459l86.701 86.788z" fill="#B53629"/>
                            <path d="m217.43 146.54c3.238 0 4.823-2.822 4.823-5.557 0-2.832-1.653-5.567-4.823-5.567h-18.44c-3.605 0-5.615 2.986-5.615 6.282v45.317c0 4.04 2.3 6.282 5.412 6.282 3.093 0 5.403-2.242 5.403-6.282v-12.438h11.153c3.46 0 5.19-2.832 5.19-5.644 0-2.754-1.73-5.49-5.19-5.49h-11.153v-16.903h13.24zm-62.327-11.124h-13.492c-3.663 0-6.263 2.513-6.263 6.243v45.395c0 4.629 3.74 6.079 6.417 6.079h14.159c16.758 0 27.824-11.027 27.824-28.047-9e-3 -17.995-10.427-29.67-28.645-29.67zm0.648 46.526h-8.225v-35.334h7.413c11.221 0 16.101 7.529 16.101 17.918 0 9.723-4.794 17.416-15.289 17.416zm-49.425-46.526h-13.366c-3.779 0-5.886 2.493-5.886 6.282v45.317c0 4.04 2.416 6.282 5.663 6.282s5.663-2.242 5.663-6.282v-13.231h8.379c10.341 0 18.875-7.326 18.875-19.107 1e-3 -11.529-8.233-19.261-19.328-19.261zm-0.222 27.738h-7.703v-17.097h7.703c4.755 0 7.78 3.711 7.78 8.553-0.01 4.833-3.025 8.544-7.78 8.544z" fill="#fff"/>
                        </svg>

                        <svg style={{width: 'auto', height: '24px', paddingLeft: '15px'}} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                        <path d="m21 7h40v54h-40z" fill="#ff7956"/>
                        <path d="m49 7 8 8h-10a2.006 2.006 0 0 1-2-2v-10z" fill="#ffda44"/>
                        <path d="m57 15v42h-40v-54h28v10a2.006 2.006 0 0 0 2 2z" fill="#ffeb99"/>
                        <path d="m3 22h50v20h-50z" fill="#2488ff"/>
                        <path d="m17 42h37v1h-37z" fill="#bfb073"/>
                        <path d="m53 23h1v20h-1z" fill="#bfb073"/>
                        <path d="m21 57h37v1h-37z" fill="#bf5b41"/>
                        <path d="m58 58h-1v-42l1 1z" fill="#bf5b41"/>
                        <path d="m57 15v1h-8a2 2 0 0 1-1.73-1z" fill="#bfb073"/>
                        <g fill="#fff">
                        <path d="m32 25h-8a1 1 0 0 0-1 1v13h2v-6h7a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6h-6v-4h6z"/>
                        <path d="m18 25h-4a3 3 0 0 0-3 3v11h2v-6h6v6h2v-11a3 3 0 0 0-3-3zm-5 6v-3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3z"/>
                        <path d="m42.168 25.445-3.703 5.555h-1.465v-6h-2v14h2v-6h1.465l3.7 5.555a1 1 0 0 0 .835.445h2v-2h-1.465l-3.335-5 3.333-5h1.467v-2h-2a1 1 0 0 0 -.832.445z"/>
                        </g>
                        </svg>

                        <svg style={{ width: 'auto', height: '24px', paddingLeft: '15px' }} enableBackground="new 0 0 469.333 469.333" version="1.1" viewBox="0 0 469.33 469.33" xmlns="http://www.w3.org/2000/svg">
                        <path d="m469.33 238.22v-120.89c0-2.833-1.125-5.542-3.125-7.542l-106.67-106.67c-2-2-4.708-3.125-7.542-3.125h-309.33c-23.532 0-42.667 19.135-42.667 42.667v96c0 1.656 0.385 3.292 1.125 4.771l19.438 38.885-20.115 67.062c-0.292 0.99-0.448 2.021-0.448 3.063v174.22c0 23.531 19.135 42.667 42.667 42.667h61.865c2.219 0 4.385-0.698 6.198-1.99l39.917-28.51 69.156 29.635c1.333 0.573 2.76 0.865 4.198 0.865 0.698 0 1.396-0.073 2.094-0.208l101.06-20.208 17.302 17.292c2 2 4.708 3.125 7.542 3.125h74.667c23.531 0 42.667-19.135 42.667-42.667v-32c0-1.656-0.385-3.292-1.125-4.771l-40.052-80.094 39.656-66.094c0.998-1.657 1.518-3.553 1.518-5.49z" fill="#303C42"/>
                        <path d="M362.667,36.417l70.25,70.25H384c-11.76,0-21.333-9.573-21.333-21.333V36.417z" fill="#1E88E5"/>
                        <path d="m448 426.67c0 11.76-9.573 21.333-21.333 21.333h-70.25l-6.008-6.008 15.633-5.211c5.583-1.854 8.604-7.896 6.74-13.49-1.865-5.583-7.875-8.604-13.49-6.74l-31.344 10.449-102.79 20.563-57.267-24.544c3.715-4.19 3.664-10.548-0.348-14.56-4.167-4.167-10.917-4.167-15.083 0l-10.052 10.052-41.291 29.489h-58.448c-11.76 0-21.333-9.573-21.333-21.333v-42.667h420.07l6.593 13.188v29.479z" fill="#00AAE7"/>
                        <path d="m430.74 362.67h-409.41v-38.25l96-96 67.125 67.125c4.167 4.167 10.917 4.167 15.083 0 4.167-4.167 4.167-10.917 0-15.083l-19.125-19.125 107.58-107.58 130.57 130.57-11.714 19.526c-0.064 0.105-0.042 0.236-0.102 0.342-0.081 0.143-0.22 0.228-0.294 0.376l-10.667 21.333c-2.635 5.271-0.5 11.677 4.771 14.313 1.531 0.76 3.156 1.125 4.76 1.125 3.917 0 7.677-2.156 9.552-5.896l1.125-2.25 14.741 29.48z" fill="#FAFAFA"/>
                        <path d="m429.88 265.46-134.34-134.34c-4.167-4.167-10.917-4.167-15.083 0l-115.13 115.12-40.458-40.458c-4.167-4.167-10.917-4.167-15.083 0l-88.459 88.458v-40.24l20.62-68.729 20.922-41.844c2.635-5.271 0.5-11.677-4.771-14.313-5.271-2.625-11.677-0.5-14.313 4.771l-11.791 23.584-10.667-21.333v-93.479c0-11.76 9.573-21.333 21.333-21.333h298.67v64c0 23.531 19.136 42.666 42.667 42.666h64v107.26l-18.12 30.204z" fill="#00AAE7"/>
                        <circle cx="160" cy="117.33" r="53.333" fill="#303C42"/>
                        <circle cx="160" cy="117.33" r="32" fill="#FAFAFA"/>
                        <path d="m181.08 93.444c0.069 0.858 0.258 1.681 0.258 2.556 0 17.646-14.354 32-32 32-8.105 0-15.431-3.126-21.076-8.111 1.328 16.435 14.971 29.444 31.742 29.444 17.646 0 32-14.354 32-32 0-9.54-4.281-18.022-10.924-23.889z" enable-background="new" opacity=".1"/>
                        <path d="M384,91.583c-11.76,0-21.333-9.573-21.333-21.333v15.083  c0,11.76,9.573,21.333,21.333,21.333h48.917l-15.083-15.083H384z" enable-background="new" opacity=".1"/>
                        <linearGradient id="b" x1="-49.46" x2="-45.081" y1="619.66" y2="615.28" gradientTransform="matrix(21.333 0 0 -21.333 1430.1 13315)" gradientUnits="userSpaceOnUse">
                            <stop stop-opacity=".1" offset="0"/>
                            <stop stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <path d="M384,128c-11.418,0-21.747-4.566-29.411-11.893L448,209.518V128H384z" fill="url(#b)"/>
                        <linearGradient id="a" x1="-69.031" x2="-43.726" y1="618.32" y2="606.52" gradientTransform="matrix(21.333 0 0 -21.333 1430.1 13315)" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#fff" stop-opacity=".2" offset="0"/>
                            <stop stop-color="#fff" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <path d="m469.33 238.22v-120.89c0-2.833-1.125-5.542-3.125-7.542l-106.67-106.67c-2-2-4.708-3.125-7.542-3.125h-309.33c-23.532 0-42.667 19.135-42.667 42.667v96c0 1.656 0.385 3.292 1.125 4.771l19.438 38.885-20.115 67.062c-0.292 0.99-0.448 2.021-0.448 3.063v174.22c0 23.531 19.135 42.667 42.667 42.667h61.865c2.219 0 4.385-0.698 6.198-1.99l39.917-28.51 69.156 29.635c1.333 0.573 2.76 0.865 4.198 0.865 0.698 0 1.396-0.073 2.094-0.208l101.06-20.208 17.302 17.292c2 2 4.708 3.125 7.542 3.125h74.667c23.531 0 42.667-19.135 42.667-42.667v-32c0-1.656-0.385-3.292-1.125-4.771l-40.052-80.094 39.656-66.094c0.998-1.657 1.518-3.553 1.518-5.49z" fill="url(#a)"/>
                        </svg>

                    </Menu.Item>
                </Menu.Menu>
            }>
            	
            </Main>
        );
    }
}

export { Uploads };
